<template>
  <main>
    <section>
      <h1>Controleren</h1>
    </section>

    <section>
      <dl>
        <dt>Persoonsgegevens</dt>
        <dd>
          <label>Postcode:</label>
          <div class="value">
            {{ zipcode }}
          </div>
        </dd>
        <dd>
          <label>Geboortejaar:</label>
          <div class="value">
            {{ yearOfBirth }}
          </div>
        </dd>
        <dd>
          <label>Telefoonnummer:</label>
          <div class="value">
            {{ phonenumber }}
          </div>
        </dd>
        <dd>
          <label>E-mail:</label>
          <div class="value">
            {{ emailAddress }}
          </div>
        </dd>
      </dl>

      <alert
        mode="error with-icon"
        icon="error"
        v-if="error"
        role="img"
        aria-label="Foutmelding"
      >{{ error }}</alert>
    </section>

    <section>
      <verification-form @verify-code="isVerified"></verification-form>
    </section>
  </main>
</template>

<script>
import Alert from '@/SharedKernel/Atoms/Alerts/Alert.vue';
import VerificationForm from '@/SharedKernel/Molecules/Verifications/Form.vue';

export default {
  components: {
    Alert,
    VerificationForm,
  },
  data () {
    return {
      error: false,
    };
  },
  computed: {
    voucherCode () {
      return this.$store.getters['VoucherCodeModule/voucherCode'];
    },
    zipcode () {
      return this.$store.getters['ContactInformationModule/zipcode'];
    },
    yearOfBirth () {
      return this.$store.getters['ContactInformationModule/yearOfBirth'];
    },
    phonenumber () {
      return this.$store.getters['ContactInformationModule/phonenumber'];
    },
    emailAddress () {
      return this.$store.getters['ContactInformationModule/emailAddress'];
    },
  },

  methods: {
    isVerified (verified) {
      if (!verified) {
        return;
      }
      this.$store.dispatch('ContactInformationModule/signedUp', true);
      this.$router.push('/aangemeld');
    }
  }
};
</script>

<style scoped>
dl {
  margin: 0 0 40px;
}
dt {
  display: flex;
  justify-content: space-between;
  margin: 0 0 10px;
  font-weight: bold;
}
dt a {
  font-weight: normal;
}
dd {
  padding: 5px 10px;
  border-width: 0 1px;
  border-style: solid;
  border-color: #000;
}
dd:last-child {
  padding-bottom: 10px;
  border-bottom-width: 1px;
}
dt + dd {
  padding-top: 10px;
  border-top-width: 1px;
}
.location {
  margin: 0 0 20px;
}
.location:last-child {
  margin: 0;
}
@media screen and (min-width: 650px) {
  dd {
    display: table;
    width: 100%;
    padding: 0 10px;
  }
  dd > * {
    display: table-cell;
  }
  dd label {
    width: 180px;
  }
}
</style>
