<template>
  <main>
    <section>
      <h1>Kloppen uw gegevens?</h1>
      <p>Hieronder staan uw gegevens. Controleer of ze juist zijn.</p>
    </section>

    <section>
      <dl>
        <dt>Persoonsgegevens <router-link to="/contactgegevens">Bewerken</router-link>
        </dt>
        <dd>
          <label>Postcode:</label>
          <div class="value">
            {{ zipcode }}
          </div>
        </dd>
        <dd>
          <label>Geboortejaar:</label>
          <div class="value">
            {{ yearOfBirth }}
          </div>
        </dd>
        <dd>
          <label>Telefoonnummer:</label>
          <div class="value">
            {{ phonenumber }}
          </div>
        </dd>
        <dd>
          <label>E-mail:</label>
          <div class="value">
            {{ emailAddress }}
          </div>
        </dd>
      </dl>

      <alert
        mode="error with-icon"
        icon="error"
        v-if="error"
        role="img"
        aria-label="Foutmelding"
      >{{ error }}</alert>
    </section>

    <section class="navigation-buttons">
      <router-button
        to="/contactgegevens"
        class="secondary"
      ><span class="icon arrow-head-left"></span> Vorige</router-button>
      <base-button
        @click.prevent.native="registerUser"
        type="button"
      >Aanmelden <span class="icon arrow-head-right"></span></base-button>
    </section>
  </main>
</template>

<script>
import RouterButton from '@/SharedKernel/Atoms/Buttons/RouterButton.vue';
import BaseButton from '@/SharedKernel/Atoms/Buttons/BaseButton.vue';
import Alert from '@/SharedKernel/Atoms/Alerts/Alert.vue';

import Verification from '@/Infrastracture/Verification';
const VerificationResource = new Verification();

export default {
  components: {
    RouterButton,
    BaseButton,
    Alert,
  },
  data () {
    return {
      error: false,
    };
  },
  computed: {
    zipcode () {
      return this.$store.getters['ContactInformationModule/zipcode'];
    },
    yearOfBirth () {
      return this.$store.getters['ContactInformationModule/yearOfBirth'];
    },
    emailAddress () {
      return this.$store.getters['ContactInformationModule/emailAddress'];
    },
    phonenumber () {
      return this.$store.getters['ContactInformationModule/phonenumber'];
    },
    voucherCode () {
      return this.$store.getters['VoucherCodeModule/voucherCode'];
    },
  },
  methods: {
    registerUser () {
      const waiting_list_id = '01GPXWKFT5942Z5G04YR5T4WGC';

      VerificationResource
        .requestCode(waiting_list_id, this.voucherCode, this.zipcode, this.yearOfBirth, this.emailAddress, this.phonenumber)
        .then((data) => {
          console.log('test');
          if (data.success) {
            this.$store.dispatch('ContactInformationModule/setAttendee', data.attendee);
            this.$router.push('/verifieren');
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = 'Er is iets misgegaan probeer het over een paar seconden opnieuw.'

          if (error.response.data.errors.unique_phone_number) {
            this.error = 'U heeft zich al aangemeld. U kunt uzelf maar eenmalig op de wachtrij plaatsen.';
          }
        });
    },
  }
};
</script>

<style scoped>
dl {
  margin: 0 0 40px;
}
dt {
  display: flex;
  justify-content: space-between;
  margin: 0 0 10px;
  font-weight: bold;
}
dt a {
  font-weight: normal;
}
dd {
  padding: 5px 10px;
  border-width: 0 1px;
  border-style: solid;
  border-color: #000;
}
dd:last-child {
  padding-bottom: 10px;
  border-bottom-width: 1px;
}
dt + dd {
  padding-top: 10px;
  border-top-width: 1px;
}
.location {
  margin: 0 0 20px;
}
.location:last-child {
  margin: 0;
}
@media screen and (min-width: 650px) {
  dd {
    display: table;
    width: 100%;
    padding: 0 10px;
  }
  dd > * {
    display: table-cell;
  }
  dd label {
    width: 180px;
  }
}
</style>
