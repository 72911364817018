<template>
  <main>
    <form @submit.prevent="submitForm">
      <section>
        <h1>Vul uw contactgegevens in</h1>
        <p>Om u te kunnen uitnodigen voor een Coronaprik hebben wij uw geboortedatum en e-mailadres nodig.</p>
      </section>

      <section>
        <div class="form-control">
          <label for="zip_code">Postcode *</label>
          <input
            type="text"
            id="zip_code"
            maxlength="4"
            placeholder="1234"
            v-model="zipcode"
            @input="zipcodeFormat(); inputZipcode();"
            autocomplete="off"
            pattern="[0-9]*"
            inputmode="numeric"
            autofocus
            :class="{ error: zipcodeError }"
          />
          <alert
            mode="error with-icon"
            icon="error"
            v-if="zipcodeError"
            role="img"
            aria-label="Foutmelding"
          >{{ zipcodeError }}</alert>
        </div>

        <div class="form-control">
          <label for="year-of-birth">Vul uw geboortejaar in (overeenkomend met het geboortejaar dat bekend is bij DigiD) *</label>
          <div
            class="selectbox"
            id="year-of-birth-select"
          >
            <select
              id="year-of-birth"
              :value="yearOfBirth"
              @input="inputYearOfBirth"
              autofocus
            >
              <option :value="minimumYear">{{ minimumYear }}</option>
              <option
                v-for="year in years"
                :key="year"
                :value="minimumYear + year"
              >{{ minimumYear + year }}</option>
            </select>
          </div>
          <alert
            mode="error with-icon"
            icon="error"
            v-if="yearOfBirthError"
            role="img"
            aria-label="Foutmelding"
          >{{ yearOfBirthError }}</alert>
        </div>

        <div class="form-control">
          <label for="phonenumber">Telefoonnummer *</label>
          <input
            type="text"
            id="phonenumber"
            placeholder="06 12345678"
            v-model.trim="phonenumber"
            :class="{ error: phonenumberError }"
            @input="isValidDutchMobilePhonenumber($event.target.value); inputPhonenumber();"
          />
          <alert
            mode="error with-icon"
            icon="error"
            v-if="phonenumberError"
            role="img"
            aria-label="Foutmelding"
          >{{ phonenumberError }}</alert>
        </div>

        <div class="form-control">
          <label for="repeat-phonenumber">Herhaal telefoonnummer *</label>
          <input
            type="text"
            id="repeat-phonenumber"
            placeholder="06 12345678"
            v-model.trim="repeatPhonenumber"
            :class="{ error: repeatPhonenumberError }"
            @input="isValidDutchMobilePhonenumber($event.target.value); inputRepeatPhonenumber();"
          />
          <alert
            mode="error with-icon"
            icon="error"
            v-if="repeatPhonenumberError"
            role="img"
            aria-label="Foutmelding"
          >{{ repeatPhonenumberError }}</alert>
        </div>

        <div class="form-control">
          <label for="email-address">E-mail *</label>
          <input
            type="email"
            id="email-address"
            placeholder="voorbeeld@provider.nl"
            v-model.trim="emailAddress"
            @input="inputEmail"
            :class="{ error: emailError }"
          />
          <alert
            mode="error with-icon"
            icon="error"
            v-if="emailError"
            role="img"
            aria-label="Foutmelding"
          >{{ emailError }}</alert>
        </div>

        <div class="form-control">
          <label for="repeat-email-address">Herhaal e-mail *</label>
          <input
            type="email"
            id="repeat-email-address"
            placeholder="voorbeeld@provider.nl"
            v-model.trim="repeatEmailAddress"
            @input="inputRepeatEmail"
            :class="{ error: repeatEmailError }"
          />
          <alert
            mode="error with-icon"
            icon="error"
            v-if="repeatEmailError"
            role="img"
            aria-label="Foutmelding"
          >{{ repeatEmailError }}</alert>
        </div>
      </section>

      <section>
        <p>* Verplichte velden</p>
      </section>

      <section class="navigation-buttons">
        <router-button
          to="/"
          class="secondary"
        ><span class="icon arrow-head-left"></span> Vorige</router-button>
        <base-button :disabled="existingUser">
          Volgende
          <span class="icon arrow-head-right"></span>
        </base-button>
      </section>
    </form>
  </main>
</template>

<script>
import RouterButton from '@/SharedKernel/Atoms/Buttons/RouterButton.vue';
import BaseButton from '@/SharedKernel/Atoms/Buttons/BaseButton.vue';
import Alert from '@/SharedKernel/Atoms/Alerts/Alert.vue';

export default {
  components: {
    RouterButton,
    BaseButton,
    Alert,
  },

  data () {
    return {
      zipcode: null,
      minimumYear: 1900,
      currentYear: new Date().getFullYear(),
      yearOfBirth: null,
      phonenumber: null,
      repeatPhonenumber: null,
      emailAddress: null,
      repeatEmailAddress: null,

      zipcodeError: false,
      yearOfBirthError: false,
      isValidPhonenumber: false,
      phonenumberError: false,
      repeatPhonenumberError: false,
      emailError: false,
      repeatEmailError: false,
      showVerificationForm: false,
      existingUser: false,
      verificationId: null,
    };
  },

  computed: {
    storeYearOfBirth () {
      return this.$store.getters['ContactInformationModule/yearOfBirth']
    },
    storeEmailAddress () {
      return this.$store.getters['ContactInformationModule/emailAddress']
    },
    storePhonenumber () {
      return this.$store.getters['ContactInformationModule/phonenumber']
    },
    years () {
      return this.currentYear - this.minimumYear;
    },
  },

  created () {
    this.yearOfBirth = this.storeYearOfBirth;
    this.emailAddress = this.storeEmailAddress;
    this.phonenumber = this.storePhonenumber;
  },

  methods: {
    zipcodeFormat () {
      this.zipcode = this.zipcode.substring(0, 4);
      this.zipcode = this.zipcode.replace(/[^0-9]+/g, '');
    },
    isValidEmail (emailAddress) {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return regex.test(emailAddress);
    },
    isValidDutchMobilePhonenumber (phonenumber) {
      const regex = /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/;
      const newPhonenumber = phonenumber
        .replace(/\D/g,'')
        .replace(/^31/g, '0')
        .replace(/^0031/g, '0');

      return regex.test(newPhonenumber) && newPhonenumber.substring(0, 2) == '06';
    },

    inputZipcode () {
      this.zipcodeError = false;
    },
    inputYearOfBirth (event) {
      this.yearOfBirth = event.target.value;
      this.yearOfBirthError = false;
    },
    inputPhonenumber () {
      this.phonenumberError = false;
    },
    inputRepeatPhonenumber () {
      this.repeatPhonenumberError = false;
    },
    inputEmail () {
      this.emailError = false;
    },
    inputRepeatEmail () {
      this.repeatEmailError = false;
    },

    submitForm () {
      const zipCodeRegex = new RegExp('^[1-9][0-9]{3}$', 'g');

      this.phonenumber = this.phonenumber
        .replace(/\D/g,'')
        .replace(/^31/g, '0')
        .replace(/^0031/g, '0');

      if (!this.zipcode || zipCodeRegex.test(this.zipcode) !== true) {
        this.zipcodeError = 'Vul uw postcode in';
      }

      if (!this.yearOfBirth || this.yearOfBirth.length === 0) {
        this.yearOfBirthError = 'Vul uw geboortejaar in';
      } else {
        this.yearOfBirthError = false;
      }

      if (!this.phonenumber || this.phonenumber.length < 10 || !this.isValidDutchMobilePhonenumber(this.phonenumber)) {
        this.phonenumberError = 'Vul uw telefoonnummer in';
      } else {
        this.phonenumberError = false;
      }

      if (this.repeatPhonenumber !== this.phonenumber) {
        this.repeatPhonenumberError = 'Uw telefoonnummer komt niet overeen';
      } else {
        this.repeatPhonenumberError = false;
      }

      if (!this.emailAddress || this.emailAddress.length === 0 || !this.isValidEmail(this.emailAddress)) {
        this.emailError = 'Vul uw e-mailadres in';
      } else {
        this.emailError = false;
      }

      if (this.repeatEmailAddress !== this.emailAddress) {
        this.repeatEmailError = 'Uw e-mailadres komt niet overeen';
      } else {
        this.repeatEmailError = false;
      }

      if (this.zipcodeError || this.yearOfBirthError || this.emailError || this.phonenumberError || this.repeatPhonenumberError || this.repeatEmailError) {
        return;
      }

      const actionPayload = {
        zipcode: this.zipcode,
        yearOfBirth: this.yearOfBirth,
        emailAddress: this.emailAddress,
        phonenumber: this.phonenumber,
      };

      this.$store.dispatch('ContactInformationModule/contactInformation', actionPayload);
      this.$router.push('overzicht');
    },
  },
};
</script>

<style scoped>
.form-control {
  margin-bottom: 40px;
}
.form-control:after {
  content: "";
  display: block;
  clear: both;
}
.form-control label {
  display: block;
  margin: 0 0 10px;
  font-weight: bold;
}
.form-control input:read-only {
  opacity: 0.6;
}
#zip_code {
  width: 120px;
  text-transform: uppercase;
}
#year-of-birth-select {
  width: 120px;
}
#verify-email-address-button {
  width: 100%;
  margin-top: 15px;
}
#phonenumber {
  margin-bottom: 10px;
}
#send-phone-number-button {
  line-height: 28px;
}
@media screen and (min-width: 650px) {
  #verify-email-address-button {
    width: auto;
  }
  #phonenumber {
    max-width: 200px;
    margin: 0 10px 0 0;
  }
}
</style>
