<template>
  <main>
    <section>
      <h1>U bent Aangemeld</h1>

      <p>Bedankt voor uw aanmelding. U staat nu in de wachtrij. Als zorgmedewerker bent u sneller aan de beurt voor de Coronaprik. U ontvangt een bevestiging per e-mail.</p>

      <p>De GGD stuurt u sms en/of email zodra u een afspraak kunt inplannen. Hierin staat ook meer informatie over het plannen van de afspraak. Het oproepen gebeurt op basis van postcode en leeftijd.</p>

      <p>Elke dag worden meer tijdsloten opengezet en extra ruimte vrij gemaakt voor afspraken voor de zorgmedewerkers. We verwachten dat alle zorgmedewerkers van 18 jaar of ouder voor 1 januari uitgenodigd of de Coronaprik hebben ontvangen.</p>

      <p>Met vriendelijke groet,
        <br>
        Team COVID-19-vaccinaties RIVM
      </p>

      <p>
        <router-button to="/">Ga terug naar home</router-button>
      </p>
    </section>
  </main>
</template>

<script>
import Session from '@/Infrastracture/Session';
import RouterButton from '../../SharedKernel/Atoms/Buttons/RouterButton.vue';
const LocalSession = new Session();

export default {
  components: { RouterButton },
  created () {
    LocalSession.clear();
    this.$store.dispatch('ContactInformationModule/resetData');
  },
}
</script>
