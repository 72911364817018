<template>
  <main>
    <section>
      <h1>Online aanmelden voor een boostervaccinatie</h1>
      <h2>Wat heeft u nodig om online aan te melden?</h2>
      <p>Een unieke code. Die vindt u in de uitnodiging die u van uw werkgever heeft ontvangen.</p>
      <h2>Meld u aan in 2 korte stappen:</h2>
      <ul>
        <li>Stap 1: Vul uw unieke code in</li>
        <li>Stap 2: Invullen contactgegevens</li>
      </ul>
      <p>Met het aanmelden plaats u zichzelf op een wachtlijst. Hiermee heeft u versnelde toegang tot een boostervaccinatie. Als u aan de beurt bent krijgt u een sms-/emailbericht met instructie om een afspraak te maken bij de GGD.</p>
      <p>Wilt u weten wat we met uw gegevens doen? Lees onze <a href="https://www.rivm.nl/documenten/privacyverklaring-rivm" target="_blank" rel="noopener noreferrer">privacyverklaring</a>.</p>
    </section>

    <section>
      <alert
        mode="with-icon"
        icon="warning"
        role="img"
        aria-label="Waarschuwing"
      >
        Heeft u klachten die op corona lijken zoals verkoudheid, koorts of benauwdheid? Wordt u binnenkort geopereerd? Dan kunt u zich niet aanmelden.
      </alert>
    </section>

    <section class="navigation-buttons">
      <router-button to="/vaccinatiecode">Starten</router-button>
    </section>
  </main>
</template>

<script>
import Alert from '@/SharedKernel/Atoms/Alerts/Alert.vue';
import RouterButton from '@/SharedKernel/Atoms/Buttons/RouterButton.vue';

export default {
  components: { Alert, RouterButton },
};
</script>
